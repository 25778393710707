import React from 'react'
import ModalDialog from '../Dialog/Dialog'
import NeedHelp from '../NeedHelp/NeedHelp'
import PrimaryButton from '../Buttons/PrimaryButton'
import quickTipsContent from "../../assets/QuickTips.htm";
import classes from './QuickTips.module.css'

function QuickTips(props) {
    return (
        <ModalDialog title="Quick Tips Guide"
            className={classes.quickTipsDialog}
            contentClassName={classes.quickTipsContainer}
            content={
                <iframe src={quickTipsContent} frameBorder="0" scrolling="no" onLoad={e => {
                    const obj = e.target;
                    obj.style.height = (obj.contentWindow.document.body.scrollHeight) + 'px';
                }} />
            }
            footer={
                <div className={classes.actions}>
                    <div className={classes.needHelp}>
                        <NeedHelp />
                    </div>
                    <PrimaryButton className={classes.closeButton} onClick={props.onClose} negative>
                        Close
                    </PrimaryButton>
                </div>
            }
        />
    )
}

export default QuickTips;