import React from 'react'
import ModalDialog from '../Dialog/Dialog'
import PrimaryButton from '../Buttons/PrimaryButton'
import classes from './Help.module.css'

function Help(props) {
    const title = props.title || "Help";
    const size = props.size || "lg";
    return (
        <ModalDialog title={title}
            className={classes.helpDialog}
            contentClassName={`${classes.helpContainer} ${size === "md" ? "helpContainer-widthMd" : "helpContainer-widthLg"}`}
            content={
                <iframe src={props.helpContent} frameBorder="0" scrolling="no" onLoad={e => {
                    const obj = e.target;
                    obj.style.height = (obj.contentWindow.document.body.scrollHeight - 10) + 'px';
                }} />
            }
            footer={
                <div className={classes.actions}>
                    <PrimaryButton className={classes.closeButton} onClick={props.onClose} negative>
                        Close
                    </PrimaryButton>
                </div>
            }
        />
    )
}

export default Help;