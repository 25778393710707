import React, { useState, Suspense, useEffect } from "react";
import { useHistory } from 'react-router';
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import Password from "../../components/Password/Password";
import Utils from "../../Utils";
import classes from "./SignUp.module.css";
import Card from "../../components/Card/Card";
import { createUser, verifyUser, resendCode, forgotPassword, logout } from '../User/UserActions';
import MessageDialog from "../../components/MessageDialog/MessageDialog";
import Verification from "./Verification/Verification";
import Input from "../../components/Input/Input";
import Consts from "../../Consts";
import LinkButton from "../../components/Buttons/LinkButton";
import NeedHelp from "../../components/NeedHelp/NeedHelp";
import createAccountHelpContent from "../../assets/help/CreateAccount.htm";
import updatePasswordHelpContent from "../../assets/help/UpdatePassword.htm";
import verifyEmailHelpContent from "../../assets/help/VerifyEmail.htm";
import invalidEmailHelpContent from "../../assets/help/InvalidEmail.htm";
import Help from "../../components/Help/Help";

const PasswordStrengthBar = React.lazy(() => import('react-password-strength-bar'));
function SignUp(props) {
    const history = useHistory();

    const [userDetails, setUserDetails] = useState(props.userDetails || {});
    const [passwordScore, setPasswordScore] = useState(0);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [showMessageDialog, setShowMessageDialog] = useState(false);
    const [showVerification, setShowVerification] = useState(false);
    const [showVerifyError, setShowVerifyError] = useState(false);
    const [isCodeResent, setIsCodeResent] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(props.isForgotPassword);
    const [dialogContent, setDialogContent] = useState({ title: "", message: "" });
    const [showHelp, setShowHelp] = useState(false);
    const [helpContent, setHelpContent] = useState({ title: "", content: ""});

    useEffect(() => {
        if (Utils.isUserLoggedIn()) {
            logout();
        }
    }, []);

    function createHandler() {
        if (Utils.validateEmail(userDetails.userId).valid) {
            if (passwordScore >= 1) {
                const data = { ...userDetails };
                let method;
                if (props.isForgotPassword) {
                    method = forgotPassword;
                } else {
                    method = createUser;
                }

                method(data).then((response) => {
                    if (response && response.data && response.data.success) {
                        setShowVerification(true);
                    } else if (response.data?.errorCode === "bad_credentials") {
                        // Forgot password with a non FLM account.
                        setHelpContent({
                            title: "Invalid Email",
                            content: invalidEmailHelpContent
                        });
                        setShowHelp(true);
                    } else if (response.data?.errorCode === "already_exist") {
                        setDialogContent(() => {
                            const obj = {
                                title: "Failed",
                                message: "User already exists."
                            };
                            return obj;
                        });
                        setShowMessageDialog(true);
                    } else if (response.data?.errorCode === "wrong_password") {
                        setDialogContent(() => {
                            const obj = {
                                title: "Failed",
                                message: "Incorrect password."
                            };
                            return obj;
                        });
                        setShowMessageDialog(true);
                    } else if (response.data?.errorCode === "unauthorized") {
                        setDialogContent(() => {
                            const obj = {
                                title: "Failed",
                                message: "Unauthorized user."
                            };
                            return obj;
                        });
                    }
                });
            }
            setIsEmailValid(true);
        } else {
            setIsEmailValid(false);
        }
    }

    function verificationHandler(verificationCode) {
        const data = { ...userDetails };
        data.otp = verificationCode;
        setIsCodeResent(false);
        verifyUser(data).then((response) => {
            if (response && response?.data?.success) {
                setShowVerification(false);
                history.push('/license-manager');
            } else {
                setShowVerifyError(true);
            }
        });
    }

    function dialogCloseHandler() {
        setShowMessageDialog(false);
    }

    function resendCodeHandler() {
        setIsCodeResent(false);
        setShowVerifyError(false);
        const data = { ...userDetails };
        resendCode(data).then((response) => {
            if (response && response?.data?.success) {
                setIsCodeResent(true);
            }
        });
    }

    function closeHandler() {
        history.goBack();
    }

    function goToSignin() {
        history.push({
            pathname: '/license-manager/sign-in'
        });
    }

    function helpHandler(helpType) {
        if (helpType === "signup") {
            setHelpContent({
                title: "Create Account Help",
                content: createAccountHelpContent
            });
        } else if (helpType === "update_password") {
            setHelpContent({
                title: "Update Password Help",
                content: updatePasswordHelpContent
            });
        } else if (helpType === "verify_email") {
            setHelpContent({
                title: "Verify Email Help",
                content: verifyEmailHelpContent
            });
        }
        setShowHelp(true);
    }

    return (
        <div className={classes.mainBox}>
            {showMessageDialog && (
                <MessageDialog
                    {...dialogContent}
                    onCancel={dialogCloseHandler}
                    isCancel
                    closeText="Close"
                    width={Consts.MESSAAGE_DIALOG_WIDTH}
                />
            )}
            {showHelp && (
                <Help
                    title={helpContent.title}
                    helpContent={helpContent.content}
                    onClose={() => setShowHelp(false)}
                    size={helpContent.title === "Invalid Email" ? "md" : "lg"}
                />
            )}
            {showVerification && (
                <Verification
                    userId={userDetails.userId}
                    isCodeResent={isCodeResent}
                    onVerify={verificationHandler}
                    onResend={resendCodeHandler}
                    showVerifyError={showVerifyError}
                    onCancel={() => { setShowVerification(false); setShowVerifyError(false); }}
                    onHelp={() => helpHandler("verify_email")}
                />
            )}
            <Card >
                <div className={classes.main}>
                    <div className={classes.heading}>
                        <h2>{props.isForgotPassword ? "Update Password" : "Create Account"}</h2>
                    </div>
                    <div className={classes.container}>
                        <label htmlFor="userId">Email</label>
                        <Input type="text"
                            id="userId"
                            autoFocus={true}
                            onChange={(e) => setUserDetails((prevState) => {
                                return {
                                    ...prevState,
                                    userId: e.target.value.trim()
                                };
                            })}
                            value={userDetails.userId}
                        />
                        {!isEmailValid && <p style={{ color: "red", marginTop: "1rem", marginBottom: "0" }}>Email is not valid </p>}
                        <label htmlFor="password">{props.isForgotPassword ? "New Password" : "Password"}</label>
                        <Password id="password" togglePasswordVisibility={true}
                            width="17rem" height="2rem"
                            onChange={(e) =>
                                setUserDetails((prevState) => {
                                    return {
                                        ...prevState,
                                        password: e.target.value
                                    };
                                })
                            } autoComplete="off" onCommit={props.isForgotPassword && createHandler} />
                        <div className={classes.passwordStrengthBarContainer}>
                            <Suspense fallback={<div>Loading...</div>}>
                                <PasswordStrengthBar password={userDetails.password} scoreWords={["weak", "weak", "okay", "good", "strong"]} scoreWordStyle={{ color: "rgb(208 , 208, 208)", fontSize: "0.75rem" }} minLength={4} shortScoreWord='too short'
                                    onChangeScore={(score) => setPasswordScore(score)} />
                            </Suspense>
                        </div>
                        {!props.isForgotPassword &&
                            <div className={classes.terms} >
                                <label>
                                    <input type="checkbox" checked={acceptTerms}
                                        onChange={e => setAcceptTerms(e.currentTarget.checked)}
                                    />I agree to the <a
                                        href="https://solutionsforscreenprinters.com/freehand-fanatical-support/software-user-guides/license-manager-eula"
                                        target="_blank" rel="noreferrer"
                                    >Terms of Use </a>
                                </label>
                            </div>
                        }
                        <div className={classes.actions} style={{ justifyContent: !props.isForgotPassword && 'center' }}>
                            {props.isForgotPassword &&
                                <>
                                    <div className={classes.needHelp} style={{ textAlign: 'start' }}>
                                        <NeedHelp onClick={() => helpHandler("update_password")} />
                                    </div>
                                    <PrimaryButton onClick={closeHandler} negative>
                                        Cancel
                                    </PrimaryButton>
                                </>
                            }
                            <PrimaryButton onClick={createHandler}
                                style={{ width: !props.isForgotPassword && '40%' }} disabled={!acceptTerms}
                            >
                                {props.isForgotPassword ? "Update" : "Create"}
                            </PrimaryButton>
                        </div>
                        {!props.isForgotPassword &&
                            <>
                                <div className={classes.signIn}>
                                    <span>Already have an account? </span>
                                    <LinkButton onClick={goToSignin} label="Login" />
                                </div>
                                <div className={classes.needHelp}>
                                    <NeedHelp onClick={() => helpHandler("signup")} />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default SignUp;