import { createSlice } from "@reduxjs/toolkit";

const initialState = { skus: [], skuMap: {} }

const skusSlice = createSlice({
    name: "skus",
    initialState,
    reducers: {
        fetchSkus: (state, action) => {
            const skus = action.payload || [];
            const skuMap = {};
            skus.forEach((sku) => {
                skuMap[sku.sku] = sku;
            })
            state.skus = skus;
            state.skuMap = skuMap;
        }
    },
});

export const skusActions = skusSlice.actions;
export default skusSlice.reducer;