import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";
import classes from './Dialog.module.css';

function ModalDialog({ title, onClose, content, footer, width, height, maxWidth, contentClassName, className }) {
    let style;
    if (width || height) {
        style = { width, height };
    }
    return <Dialog maxWidth={maxWidth}
        className={className}
        open={true} aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>
            <span>{title}</span>
            {
                onClose &&
                <IconButton style={{ float: 'right', padding: 0 }}
                    onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            }
        </DialogTitle>
        <DialogContent className={contentClassName}><div style={style} className={classes.content}>{content}</div></DialogContent>
        <DialogActions>{footer}</DialogActions>
    </Dialog>
}

export default ModalDialog;