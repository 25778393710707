import React from 'react';
import classes from "./NeedHelp.module.css";

const NeedHelp = (props) => {
    const label = props.label || "Need help?";

    return (
        props.onClick ?
            <span className={classes.link} onClick={props.onClick}>{label}</span> :
            <a className={classes.link}
                href="https://solutionsforscreenprinters.com/freehand-license-manager-guide/"
                target="_blank" rel="noreferrer"
            >
                {label}
            </a>
    );
};

export default NeedHelp;