import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {}
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    fetchProfile: (state, action) => {
      const data = action.payload;
      state.user = data.user;
    },
    deleteMachine: (state, action) => {
      const data = action.payload;
      state.user.serialData = data.serialData;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
