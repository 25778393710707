import React from "react";
import PrimaryButton from "../Buttons/PrimaryButton";
import ModalDialog from "../Dialog/Dialog";
import NeedHelp from "../NeedHelp/NeedHelp";
import classes from './MessageDialog.module.css';

function MessageDialog({ title, message, isCancel, onCancel, onAccept, closeText = 'Cancel', showNeedHelp }) {
    return <ModalDialog
        title={title}
        content={
            <div className={classes.message}>
                <p>{message}</p>
            </div>
        }
        footer={
            <div className={showNeedHelp && classes.actions}>
                {showNeedHelp && <NeedHelp />}
                <div>
                    {
                        isCancel &&
                        <PrimaryButton onClick={onCancel} negative>{closeText}</PrimaryButton>
                    }
                    {
                        onAccept &&
                        <PrimaryButton onClick={onAccept} positive>OK</PrimaryButton>
                    }
                </div>
            </div>
        }
    />;
}

export default MessageDialog;