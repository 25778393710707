import axios from '../../../axios';
import { userActions } from "../User/UserSlice";
import { productsActions } from './ProductsSlice';

export function activateProduct(data) {
    return axios({
        url: "/license/redeem",
        method: "post",
        data: data,
        responseType: "json",
    })
        .then((response) => {
            return response;
        }).catch((error) => {
            return error;
        });
}

export function fetchProducts(dispatch) {
    return axios({
        url: "/license/product",
        method: "get"
    })
        .then((response) => {
            const data = response.data
            if (data.success) {
                dispatch(productsActions.fetchProducts(data));
            }
            return response;
        }).catch((error) => {
            return error;
        });
}

export async function removeDevice(dispatch, machineData) {
    let res = await axios({
        url: "/license/user/logoutFrom",
        method: "post",
        data: machineData,
        responseType: "json",
    });
	if (res.data.success) {
		let license = await axios({
			url: "/license/user/licenses",
			responseType: "json"
		});
		dispatch(userActions.deleteMachine(license.data));
	}
	return res;
}