import React from 'react';
import classes from './Products.module.css';
import ProductCard from './ProductCard//ProductCard';
import { shallowEqual, useSelector } from 'react-redux';

const Products = (props) => {

    const products = useSelector((state) => state.products.products, shallowEqual) || {};

    return (
        <div className={classes.main}>
            <div className={classes.innerContainer}>
                <div className={classes.productsContaier}>
                    {products?.map((product) => (
                        <ProductCard {...product} key={product.productId} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Products;
