import React, {useState} from 'react'
import ModalDialog from '../Dialog/Dialog'
import PrimaryButton from '../Buttons/PrimaryButton'
import quickStartContent from "../../assets/QuickStart.htm";
import classes from './QuickStart.module.css'

function getDontShowAgain() {
    const dontShow = localStorage.getItem("dontShowQuickStart");
    if (dontShow === null) {
        return false;
    }
    return dontShow === "true";
}

function QuickStart(props) {
    const [dontShowAgain, setDontShowAgain] = useState(getDontShowAgain());

    function dontShowAgainHandler(e) {
        const dontShow = e.currentTarget.checked;
        setDontShowAgain(dontShow);
        localStorage.setItem("dontShowQuickStart", dontShow);
    }

    return (
        <ModalDialog title="Quick Start Overview"
            className={classes.quickStartDialog}
            contentClassName={classes.quickStartContainer}
            content={
                <iframe src={quickStartContent} frameBorder="0" scrolling="no" onLoad={e => {
                    const obj = e.target;
                    obj.style.height = (obj.contentWindow.document.body.scrollHeight - 10) + 'px';
                }} />
            }
            footer={
                <div className={classes.actions}>
                    <div className={classes.dontShow}>
                        <label>
                            <input type="checkbox" checked={dontShowAgain} onChange={dontShowAgainHandler} />
                            Don't show again
                        </label>
                    </div>
                    <PrimaryButton className={classes.closeButton} onClick={props.onClose} negative>
                        Close
                    </PrimaryButton>
                </div>
            }
        />
    )
}

export default QuickStart;