import React, { useState } from "react";
import { useHistory } from 'react-router';
import { signInUser } from '../User/UserActions'
import MessageDialog from "../../components/MessageDialog/MessageDialog";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import LinkButton from "../../components/Buttons/LinkButton";
import Password from "../../components/Password/Password";
import NeedHelp from "../../components/NeedHelp/NeedHelp";
import Card from "../../components/Card/Card";
import Utils from "../../Utils";
import classes from "./SignIn.module.css";
import Consts from "../../Consts";
import loginHelpContent from "../../assets/help/Login.htm"
import Help from "../../components/Help/Help";

function SignIn(props) {
    const history = useHistory();
    const [userDetails, setUserDetails] = useState(props.userDetails || {});
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [showMessageDialog, setShowMessageDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState({ title: "", message: "" });
    const [showHelp, setShowHelp] = useState(false);
    const [helpContent, setHelpContent] = useState({ title: "", content: ""});

    function signInHandler() {
        if (Utils.validateEmail(userDetails.userId).valid) {
            let data = { ...userDetails };
            if (userDetails.password) {
                signInUser(data).then((response) => {
                    const data = response?.data;
                    if (data?.success) {
                        if (!Utils.isAdmin(data.user?.role)) {
                            localStorage.setItem("isLoggedIn", true);
                            history.push(history.location?.state?.from || "/license-manager");
                        } else {
                            setDialogContent(() => {
                                return {
                                    title: "Failed",
                                    message: "Invalid email or password.",
                                };
                            });
                            setShowMessageDialog(true);
                        }
                    } else if (response.data?.errorCode) {
                        setDialogContent(() => {
                            return {
                                title: "Failed",
                                message: "Invalid email or password.",
                            };
                        });
                        setShowMessageDialog(true);
                    }
                });
                setIsPasswordValid(true);
            } else {
                setIsPasswordValid(false);
            }
            setIsEmailValid(true);
        } else {
            setIsEmailValid(false);
        }
    }

    function createHandler() {
        history.push({
            pathname: "/license-manager/sign-up",
            state: { ...history.location?.state },
        });
    }

    function forgotPasswordHandler() {
        history.push({
            pathname: "/license-manager/forgot-password",
            state: { ...history.location?.state },
        });
    }

    function helpHandler() {
        setHelpContent({
            title: "Login Help",
            content: loginHelpContent
        });
        setShowHelp(true);
    }

    return (
        <div className={classes.mainBox}>
            {showMessageDialog && (
                <MessageDialog
                    {...dialogContent}
                    onCancel={() => setShowMessageDialog(false)}
                    isCancel
                    closeText="Close"
                    width={Consts.MESSAAGE_DIALOG_WIDTH}
                />
            )}
            {showHelp && (
                <Help
                    title={helpContent.title}
                    helpContent={helpContent.content}
                    onClose={() => setShowHelp(false)}
                />
            )}
            <Card>
                <div className={classes.main}>
                    <div className={classes.heading}>
                        <h2>Login</h2>
                    </div>
                    <div className={classes.container}>
                        <label htmlFor="userId">Email</label>
                        <input
                            type="email"
                            id="userId"
                            autoFocus={true}
                            onChange={(e) =>
                                setUserDetails((prevState) => {
                                    return {
                                        ...prevState,
                                        userId: e.target.value,
                                    };
                                })
                            }
                            value={userDetails.userId || ""}
                            autoComplete="off"
                        />
                        {!isEmailValid && (
                            <p style={{ color: "red", marginTop: "1rem", marginBottom: "0" }}>
                                Email is not valid{" "}
                            </p>
                        )}
                        <label htmlFor="password">Password</label>
                        <Password
                            id="password"
                            togglePasswordVisibility={true}
                            width="17rem"
                            height="2rem"
                            onChange={(e) =>
                                setUserDetails((prevState) => {
                                    return {
                                        ...prevState,
                                        password: e.target.value,
                                    };
                                })
                            }
                            autoComplete="off"
                            onCommit={signInHandler}
                        />
                        {!isPasswordValid && (
                            <p style={{ color: "red", marginTop: "1rem", marginBottom: "0" }}>
                                Please enter the password{" "}
                            </p>
                        )}
                        <div className={classes.forgotPassword}>
                            <LinkButton
                                onClick={forgotPasswordHandler}
                                label="Forgot Password?"
                            />
                        </div>
                        <div className={classes.actions}>
                            <PrimaryButton onClick={signInHandler} style={{ width: "40%" }}>
                                Login
                            </PrimaryButton>
                        </div>
                        <div className={classes.signup}>
                            <LinkButton onClick={createHandler} label="Create" />
                            <span> new account</span>
                        </div>
                        <div className={classes.needHelp}>
                            <NeedHelp onClick={helpHandler} />
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default SignIn;