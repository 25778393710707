import React, { useState, Suspense } from 'react';
import logoImage from "../../assets/FreehandLogo.png";
import keyIcon from "../../assets/change-password.svg";
import logoutIcon from '../../assets/logout.svg';
import bulbIcon from '../../assets/lightbulb-outline.svg';
import DropDownMenu from '../../components/DropDownMenu/DropDownMenu';
import DropDownItem from '../../components/DropDownMenu/DropDownItem';
import classes from './Header.module.css';
import ModalDialog from '../../components/Dialog/Dialog';
import Password from '../../components/Password/Password';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import { logout, updateUser } from '../User/UserActions';
import MessageDialog from '../../components/MessageDialog/MessageDialog';
import Consts from '../../Consts';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Migrate from '../Migrate/Migrate';
import QuickTips from '../../components/QuickTips/QuickTips';
import QuickStart from '../../components/QuickStart/QuickStart';
import changePasswordHelpContent from "../../assets/help/ChangePassword.htm"
import NeedHelp from "../../components/NeedHelp/NeedHelp";
import Help from "../../components/Help/Help";

const PasswordStrengthBar = React.lazy(() => import('react-password-strength-bar'));

function Header() {
    const dispatch = useDispatch();
    const [showMigrate, setShowMigrate] = useState(false);
    const [showQuickTips, setShowQuickTips] = useState(false);
    const [showQuickStart, setShowQuickStart] = useState(false);
    const [showMessageDialog, setShowMessageDialog] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [passwordScore, setPasswordScore] = useState(0);
    const [userDetails, setUserDetails] = useState({ existingPassword: "", password: "" });
    const [dialogContent, setDialogContent] = useState({ title: "", message: "" });
    const [showHelp, setShowHelp] = useState(false);
    const [helpContent, setHelpContent] = useState({ title: "", content: "" });

    const user = useSelector((state) => state.user.user, shallowEqual) || {};

    function logoutUserHandler() {
        logout(dispatch);
    }

    function checkLoggedIn() {
        let token = localStorage.getItem("isLoggedIn");
        if (!token) {
            return false;
        }
        return true;
    }

    function changePasswordHandler() {
        setShowChangePassword(true);
    }

    function quickStartHandler() {
        setShowQuickStart(true);
    }

    function userDetailsHandler(property) {
        return (e) => {
            setUserDetails((prevState) => {
                const newUserDetails = { ...prevState };
                newUserDetails[property] = e.target.value;
                return newUserDetails;
            });
        };
    }

    function updateHandler() {
        if (passwordScore > 1) {
            updateUser({ ...userDetails, userId: user.userId }).then((response) => {
                const obj = {
                    title: "Failed",
                    message: "Something went wrong! Please try again."
                };
                if (response.data?.success) {
                    obj.title = "Success";
                    obj.message = "Password changed successfully.";
                    setShowChangePassword(false);
                } else if (response.data?.errorCode) {
                    obj.title = "Failed";
                    obj.message = "Sorry! You have entered wrong password.";
                }
                setDialogContent(() => {
                    return obj;
                });
                setShowMessageDialog(true);
            });
        }
    }

    function migrateLicenseHandler() {
        setShowMigrate(true);
    }

    function quickTipsHandler() {
        setShowQuickTips(true);
    }

    function helpHandler() {
        setHelpContent({
            title: "Change Password Help",
            content: changePasswordHelpContent
        });
        setShowHelp(true);
    }

    return (
        <>
            {showMigrate && <Migrate onClose={() => setShowMigrate(false)} />}
            {showQuickTips && <QuickTips onClose={() => setShowQuickTips(false)} />}
            {showQuickStart && <QuickStart onClose={() => setShowQuickStart(false)} />}
            {showHelp && <Help title={helpContent.title} helpContent={helpContent.content} onClose={() => setShowHelp(false)} />}
            {showMessageDialog && <MessageDialog {...dialogContent} onCancel={() => setShowMessageDialog(false)} isCancel closeText="Close" width={Consts.MESSAAGE_DIALOG_WIDTH} zIndex="101" />}
            {showChangePassword && <ModalDialog title="Change Password"
                content={
                    <div className={classes.passwordManager}>
                        <label htmlFor="currentPassword">Current Password</label>
                        <Password id="currentPassword" togglePasswordVisibility={true} width="16.5rem" height="2rem"
                            onChange={userDetailsHandler("existingPassword")} autoComplete="off" />
                        <label htmlFor="newPassword">New Password</label>
                        <div>
                            <Password id="newPassword" togglePasswordVisibility={true} width="16.5rem" height="2rem"
                                onChange={userDetailsHandler("password")} autoComplete="off" />
                            <Suspense fallback={<div>Loading...</div>}>
                                <PasswordStrengthBar password={userDetails.password} scoreWords={["weak", "weak", "okay", "good", "strong"]} scoreWordStyle={{ color: "rgb(208 , 208, 208)", fontSize: "0.75rem" }} minLength={4} shortScoreWord='too short'
                                    onChangeScore={(score) => setPasswordScore(score)} />
                            </Suspense>
                        </div>
                    </div>
                }
                footer={
                    <div className={classes.actions}>
                        <div className={classes.needHelp}>
                            <NeedHelp onClick={helpHandler} />
                        </div>
                        <PrimaryButton onClick={() => setShowChangePassword(false)} negative>
                            Cancel
                        </PrimaryButton>
                        <PrimaryButton onClick={updateHandler}>
                            Update
                        </PrimaryButton>
                    </div>
                }/>
            }
            <div className={classes.main}>
                <div className={classes.logoContainer}>
                    <img className={classes.logo} src={logoImage} alt='Logo' />
                    <div>
                        <p>Freehand License Manager</p>
                    </div>
                </div>
                {checkLoggedIn() &&
                    (
                        <div className={classes.logoutContainer}>
                            <PrimaryButton className={classes.migrateButton} onClick={migrateLicenseHandler}>
                                Migrate iLok License
                            </PrimaryButton>
                            <PrimaryButton className={classes.quickTipsButton} onClick={quickTipsHandler}>
                                Quick Tips
                            </PrimaryButton>
                            <DropDownMenu title={user.userId}>
                                <DropDownItem title="Change Password" onClick={changePasswordHandler} imageIcon={keyIcon} />
                                <DropDownItem title="Show Quick Start" onClick={quickStartHandler} imageIcon={bulbIcon} />
                                <DropDownItem title="Logout" onClick={logoutUserHandler} imageIcon={logoutIcon} />
                            </DropDownMenu>
                        </div>
                    )
                }
            </div>
        </>
    );
}

export default Header;