import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchProfile } from '../../../User/UserActions';
import { activateProduct } from '../../ProductsActions';
import Consts from '../../../../Consts';
import classes from './ActivateLicense.module.css';
import Dialog from '../../../../components/Dialog/Dialog';
import NeedHelp from '../../../../components/NeedHelp/NeedHelp';
import MessageDialog from '../../../../components/MessageDialog/MessageDialog';
import Input from '../../../../components/Input/Input';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import Utils from '../../../../Utils';

const getOptionString = (license) => {
    let host = license.machines?.[0]?.host;
    if (host) {
        if (host.length > 15) {
            host = host.substring(0, 12) + '...';
        }
        return `${host} (${license.serialNumber})`;
    }
    return license.serialNumber;
};

const ActivateLicense = ({ onClose, productId, allLicences = [], allowNewInstance, name, purchaseLink }) => {
    const dispatch = useDispatch();
    const [messageContent, setMessageContent] = useState({
        title: "",
        message: ""
    });
    const [showMessageDialog, setShowMessageDialog] = useState(false);
    const [serialNumber, setSerialNumber] = useState("");
    const [mode, setMode] = useState(allowNewInstance ? Consts.INSTANCE : Consts.VALIDITY);
    const [targetSerial, setTargetSerial] = useState(allLicences[0]?.serialNumber);
    const isSerialValid = Utils.validateSerialNumber(serialNumber);

    function activateHandler() {
        const data = { serialNumber, mode, productId };
        if (mode === Consts.VALIDITY) {
            data.targetSerial = targetSerial;
        }
        activateProduct(data).then((response) => {
            const data = response.data;
            if (data?.success) {
                onClose(false);
                fetchProfile(dispatch);
            } else {
                setMessageContent({
                    title: "Activation Error",
                    message: <>
                        <span> {Utils.getErrorMessage(data?.errorCode)} </span>

                    </>
                });
                setShowMessageDialog(true);
            }
        });
    }

    return (
        <>
            {
                showMessageDialog && <MessageDialog {...messageContent} closeText='Close' isCancel
                    onCancel={() => setShowMessageDialog(false)}
                    width={Consts.MESSAAGE_DIALOG_WIDTH} showNeedHelp
                />
            }
            <Dialog
                showCloseIcon={true}
                title={`Activate License (${name})`}
                width="26rem"
                content={
                    <div className={classes.activateProductContent}>
                        <label className={classes.purchaseLicense}>
                            <span>To purchase an Activation code </span>
                            <a href={purchaseLink} target="_blank" rel="noreferrer">click here.</a>
                        </label>
                        <label htmlFor='input' className={classes.activationLabel}>
                            <span>Enter Activation Code below. </span>
                        </label>
                        <Input id="input" type="text" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value.trim())} onKeyDown={(e) => e.key === 'Enter' && activateHandler()} />
                        {allowNewInstance &&
                            <div className={classes.mode}>
                                <label htmlFor="instance" className={Utils.getClasses(classes.instance, !isSerialValid && classes.disabledLabel)}>
                                    <input type="radio" id="instance" value={Consts.INSTANCE} name="redeem-mode" onChange={(e) => setMode(e.target.value)} checked={mode === Consts.INSTANCE} disabled={!isSerialValid} />Activate a computer
                                </label>
                                <label className={(!Boolean(allLicences.length) || !isSerialValid) && classes.disabledLabel} htmlFor="validity">
                                    <input type="radio" id="validity" value={Consts.VALIDITY} name="redeem-mode" onChange={(e) => setMode(e.target.value)} checked={mode === Consts.VALIDITY} disabled={!Boolean(allLicences.length) || !isSerialValid} />Extend activation
                                </label>
                                {mode === Consts.VALIDITY && isSerialValid &&
                                    <label className={classes.selecteLicense}>
                                        Select license:
                                        <select value={targetSerial} onChange={(e) => setTargetSerial(e.target.value)}>
                                            {allLicences.map((license) => (
                                                <option value={license.serialNumber} key={license.serialNumber}>{getOptionString(license)}</option>
                                            ))}
                                        </select>
                                    </label>

                                }
                            </div>
                        }
                    </div>
                }
                footer={
                    <div className={classes.activateProductActions}>
                        <NeedHelp />
                        <div>
                            <PrimaryButton onClick={() => onClose(false)} negative>
                                Cancel
                            </PrimaryButton>
                            <PrimaryButton onClick={activateHandler} positive disabled={!isSerialValid}>
                                OK
                            </PrimaryButton>
                        </div>
                    </div>}
            />
        </>
    );
};

export default ActivateLicense;