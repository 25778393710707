import React, { useState } from 'react';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import ModalDialog from '../../../components/Dialog/Dialog';
import classes from './Verification.module.css';
import LinkButton from '../../../components/Buttons/LinkButton';
import NeedHelp from '../../../components/NeedHelp/NeedHelp';

function Verification(props) {
    const [verificationCode, setVerificationCode] = useState("");

    function verificationHandler() {
        props.onVerify(verificationCode);
    }

    function resendCodeHandler() {
        setVerificationCode("");
        props.onResend();
    }

    return <ModalDialog
        title="Verify Email"
        content={
            <div className={classes.content}>
                <label htmlFor="verificationCode">Enter Verification code</label>
                <input
                    type="text"
                    id="verificationCode"
                    autoFocus={true}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && verificationHandler()}
                    value={verificationCode} />
                <LinkButton onClick={resendCodeHandler} label="Resend Code" className={classes.resendCode} />
                {props.isCodeResent && <p>Code Resent</p>}
                {!props.isCodeResent && props.showVerifyError && <p style={{ color: "red" }}>Verification failed!</p>}
                <div className={classes.tips}>
                    <p>Email verification code sent to:</p>
                    <p>{props.userId}</p>
                    <p style={{marginTop: "0.5rem"}}>Not seeing it? Check Junk/Spam folders or click Resend Code</p>
                    <p style={{marginTop: "0.5rem"}}>Code valid for 15 minutes.</p>
                </div>
            </div>
        }
        footer={
            <>
                <div className={classes.needHelp}>
                    <NeedHelp onClick={props.onHelp} />
                </div>
                <PrimaryButton onClick={props.onCancel} negative>Cancel</PrimaryButton>
                <PrimaryButton onClick={verificationHandler} positive>
                    Verify
                </PrimaryButton>
            </>
        }
    />
}

export default Verification;