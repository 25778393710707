import React from "react";
import Utils from "../../Utils";
import classes from './PrimaryButton.module.css';

function PrimaryButton({type="button", onClick, disabled, className, negative, positive, style, children}) {
    return (
        <button type={type} onClick={onClick} disabled={disabled}
            className={Utils.getClasses(classes.primaryBtn, className, negative && classes.negative, positive && classes.positive)}
            style={style}
        >{children}</button>
    );
}

export default PrimaryButton;