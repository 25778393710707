import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { removeDevice } from '../ProductsActions';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import Utils from '../../../Utils';
import classes from './ProductCard.module.css';
import ActivateLicense from './ActivateLicense/ActivateLicense';

const status = {
    inactive: "Inactive",
    active: "Active",
    trialActive: "Trial Active",
    expired: "Expired"
};

function getStatus(serialData, trials) {
    if (!serialData?.length && !trials?.length) {
        return status.inactive;
    }
    if (serialData.length) {
        return status.active;
    }
    return Date.now() < trials[0].expiryDate ? status.trialActive : status.expired;
}

function getColor(currStatus) {
    if (currStatus === status.inactive) {
        return '';
    }
    return currStatus !== status.expired ? "green" : "red";
}

const ProductCard = ({ productId, logoUrl, name, allowNewInstance, purchaseLink }) => {
    const dispatch = useDispatch();
    const skuMap = useSelector(state => state.skus.skuMap, shallowEqual) || {};
    const user = useSelector((state) => state.user.user, shallowEqual) || {};
    const [showActivateLicense, setshowActivateLicense] = useState(false);

    const serialData = user.serialData?.filter(s => {
        const products = skuMap[s.sku].products;
        return products?.some(p => p === productId);
    }) || [];
    const trials = user.trials?.filter(s => s?.productId === productId).map(t => {
        const trial = { ...t, trial: true };
        if (trial.machine) {
            trial.machines = [trial.machine];
        }
        return trial;
    }) || [];

    const status = getStatus(serialData, trials);
    const allLicences = serialData.length ? serialData : trials;

    function redeemHandler() {
        setshowActivateLicense(true);
    }

    return (
        <>
            {showActivateLicense && <ActivateLicense onClose={setshowActivateLicense} productId={productId} allLicences={serialData} allowNewInstance={allowNewInstance} name={name} purchaseLink={purchaseLink} />}
            <div className={classes.main}>
                <div className={classes.redeemContainer}>
                    <PrimaryButton onClick={redeemHandler}>
                        Redeem
                    </PrimaryButton>
                </div>
                <div className={classes.product}>
                    <img src={logoUrl} alt={`${name} logo`}></img>
                    <div className={classes.title}>{name}</div>
                    <div className={classes.productStatus} style={{ color: getColor(status) }}>{status}</div>
                    <div className={classes.container}>
                        {allLicences?.map(license => (Date.now() < license.expiryDate &&
                            <div className={classes.licenseContainer} key={license.id || license.serialNumber}>
                                <div className={classes.serialNo}>{license.serialNumber}</div><div className={classes.licenseValidity}>Expires: {Utils.getLocalFormatDate(license.expiryDate)}</div>
                                {license.machines?.map(machine => (
                                    <div className={classes.activeDevice} key={machine.fingerPrint}>
                                        <div className={classes.deviceName} title={machine.host}>{machine.host}</div>
                                        <div className={classes.lastActive}>Last login {Utils.getTimeSince(machine.loginTime)}</div>
                                        <PrimaryButton className={classes.signOut} onClick={() => removeDevice(dispatch, { ...machine, productId })}>
                                            Sign Out
                                        </PrimaryButton>
                                    </div>))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductCard;