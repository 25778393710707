import axios from '../../../axios';

export function migrateLicense(license) {
    return axios({
        url: "/license/migrate",
        method: "post",
        data: {
            serialNumber: license
        },
        responseType: "json",
    });
}
