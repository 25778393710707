import axios from '../../../axios';
import { userActions } from "./UserSlice";

export function signInUser(data) {
  return axios({
    url: "/license/user/login",
    method: "post",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      return response;
    }).catch((error) => {
      return error;
    });
}

export function logout(dispatch) {
  return axios({
    url: "/license/user/logout",
    method: "get"
  }).then((response) => {
    localStorage.removeItem("isLoggedIn");
    if (dispatch) {
      dispatch(userActions.fetchProfile({}));
      window.location = "/license-manager/sign-in";
    }
    return response;
  }).catch((error) => {
    return error;
  });
}

export function updateUser(data) {
  return axios({
    url: "/license/user/updateProfile",
    method: "post",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      return response;
    }).catch((error) => {
      return error;
    });
}

export async function fetchProfile(dispatch) {
  let response = await axios({
    url: "/license/user/profile",
    method: "get"
  });
  const data = response.data;
  if (!data.success) {
    return data;
  }
  response = await axios({
    url: "/license/user/licenses",
    method: "get"
  });
  Object.assign(data.user, response.data);

  if (data.success) {
    dispatch(userActions.fetchProfile(data));
  }
  return data;
}

export function createUser(data) {
  return axios({
    url: "/license/user/create",
    method: "post",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      return response;
    }).catch((error) => {
      return error;
    });
}

export function forgotPassword(data) {
  return axios({
    url: "/license/user/forgotpassword",
    method: "post",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      return response;
    }).catch((error) => {
      return error;
    });
}

export function verifyUser(data) {
  return axios({
    url: "/license/user/verify",
    method: "post",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        localStorage.setItem("isLoggedIn", true);
      }
      return response;
    }).catch((error) => {
      return error;
    });
}

export function resendCode(data) {
  return axios({
    url: "/license/user/resend",
    method: "post",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      return response;
    }).catch((error) => {
      return error;
    });
}