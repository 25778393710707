import { configureStore } from '@reduxjs/toolkit';
import userReducer from "./App/modules/User/UserSlice";
import productsReducer from "./App/modules/Products/ProductsSlice";
import skusReducer from './App/modules/Skus/SkusSlice';

// Configure our store combining all the state reducers.
export const store = configureStore({
  reducer: {
    user: userReducer,
    products: productsReducer,
    skus: skusReducer
  }
});
