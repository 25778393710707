const Utils = {

    isUserLoggedIn: () => {
        const token = localStorage.getItem("isLoggedIn");
        if (!token) {
            return false;
        }
        return true;
    },

    getClasses: (...cls) => {
        return cls.filter(i => i).join(' ');
    },

    isAdmin: (role) => {
        return role === 'admin';
    },

    validateEmail: (email) => {
        let obj = {
            valid: true,
            message: ""
        };

        let validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!email.match(validEmail)) {
            obj.valid = false;
            obj.message = "Your email is not valid";
            return obj;
        }
        return obj;
    },

    validateSerialNumber: (code) => {
        let regex = /^[0-9]{4}[A-Z]{0,4}(-[A-F0-9]{4}){4}$/;
        return regex.test(code);
    },

    validateLegacySerialNumber: (code) => {
        // Legacy (iLok) serial number is of the form ####-####-####-####-####-####-####-##
        // where each # is a digit 0-9
        let regex = /^[0-9]{4}(-[0-9]{4}){6}-[0-9]{2}$/;
        return regex.test(code);
    },

    getLocalFormatDate: function (time) {
        const date = new Date(time);
        const formatedDate = date.toLocaleString('en-GB', {
            day: 'numeric', month: 'short', year: 'numeric'
        });
        return formatedDate;
    },

    timeSince: (date) => {

        let seconds = Math.floor((new Date() - date) / 1000);
        let interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + " years";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " mins";
        }
        return "Just now";
        // return Math.floor(seconds) + " seconds";
    },

    getTimeSince: (date) => {
        let ans = Utils.timeSince(date);
        if (ans.includes("now")) {
            return ans;
        }
        let array = ans.split(' ');
        if (Number(array[0]) === 1) {
            ans = ans.substring(0, ans.length - 1);
        }
        return ans + ' ago';
    },

    getErrorMessage: (code) => {
        switch (code) {
            case 'invalid_key':
                return 'Enter valid activation code.';
            case 'max_failed_redeem_reached':
                return 'Maximum failed activation attempts reached. Try again in 15 minutes.';
            case 'invalid_key_for_this_product':
                return 'License key is not valid for this product.';
            default:
                return 'Something went wrong.';
        }
    }
};

export default Utils;