import axios from 'axios';

axios.interceptors.request.use(function (request) {
    window.loading(true);
    request.headers.clientID = '7ebd3ec1-118c-4c2a-abf5-2ae9b69540b0.apps.solutionsforscreenprinters.com';
    return request;
});

axios.interceptors.response.use(function (response) {
    window.loading(false);
    return response;
}, function (error) {
    if (error?.response?.status === 401) {
        window.location = "/license-manager/sign-in";
        localStorage.removeItem("isLoggedIn");
    }
    window.loading(false);
    window.error(true);
    return Promise.reject(error);
});

export default axios;