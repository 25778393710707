import React, { useEffect, useState } from "react";
import classes from './Loading.module.css';

function Loading(props) {
    const [show, setShow] = useState(false);
    useEffect(() => {
        window.loading = setShow;
        return () => delete window.loading;
    }, []);
    return (
        show && <>
            <div className={classes.backdrop}> </div>
            <div className={classes.main} >
                <span>{props.text} </span>
                <div className={classes.loader}></div>
            </div>
        </>
    );
}

export default Loading;