import { skusActions } from "./SkusSlice";
import axios from '../../../axios';

export function fetchSkus(dispatch) {
    return axios({
        url: "/license/product/skus",
        method: "get",
    }).then((response) => {
        const data = response.data
        if (data.success) {
            dispatch(skusActions.fetchSkus(data.skus));
        }
        return response;
    }).catch((error) => {
        return error;
    });
}
