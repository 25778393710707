import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router';
import Products from '../Products/Products';
import QuickStart from '../../components/QuickStart/QuickStart';
import classes from './Dashboard.module.css';
import { fetchProfile } from '../User/UserActions';
import { fetchProducts } from '../Products/ProductsActions';
import { fetchSkus } from '../Skus/SkusAction';

function shouldShowQuickStart() {
    const dontShow = localStorage.getItem("dontShowQuickStart");
    if (dontShow === null) {
        return true;
    }
    return dontShow === "false";
}

const Dashboard = (props) => {
    const url = props.match.url;
    const [showQuickStart, setShowQuickStart] = useState(shouldShowQuickStart());
    const dispatch = useDispatch();

    useEffect(() => {
        fetchSkus(dispatch);
        fetchProfile(dispatch);
        fetchProducts(dispatch);
    }, []);

    return (
        <div className={classes.main}>
            <Switch>
                <Route exact path={`${url}/settings/products`} component={Products} />
            </Switch>
            {showQuickStart && <QuickStart onClose={() => setShowQuickStart(false)} />}
        </div>
    );
};

export default Dashboard;