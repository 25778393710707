import React, { useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ModalDialog from '../../components/Dialog/Dialog';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import { fetchProfile } from '../User/UserActions';
import classes from './Migrate.module.css'
import { migrateLicense } from './MigrateActions';
import Utils from '../../Utils';
import NeedHelp from '../../components/NeedHelp/NeedHelp';

const Migrate = (props) => {

    const dispatch = useDispatch();

    const [serialNumber, setSerialNumber] = useState("");
    const [isMigrated, setMigrated] = useState(false);
    const [migrateResponse, setMigrateResponse] = useState("");

    const skuMap = useSelector(state => state.skus.skuMap, shallowEqual) || {};
    const isSerialValid = Utils.validateLegacySerialNumber(serialNumber);

    function migrateSerial() {
        migrateLicense(serialNumber)?.then((response) => {
            if (response.data.success) {
                const license = response.data.data;
                const licenseInfo = `${skuMap[license.sku].displayName}<br/>${license.serialNumber}<br/>Expires ${Utils.getLocalFormatDate(license.expiryDate)}`;
                setMigrateResponse(`<p>Successfully migrated.</p><p>${licenseInfo}</p>`);
                setMigrated(true);
                fetchProfile(dispatch);
            } else {
                const errorCode = response.data.errorCode;
                if (errorCode === "invalid_key" || errorCode === "license_expired") {
                    setMigrateResponse(
                        "<p>Activation code entered is either not valid or has already been migrated.</p>" +
                        "<p>Things to check:</p>" +
                        "<p>An expired code is considered invalid. Check order dates in your <a href=\"https://solutionsforscreenprinters.com/my-account/orders\" target=\"_blank\" rel=\"noreferrer\">My Account/Orders</a>. Renew an expired license.</p>" +
                        "<p>Check code accuracy, go to <a href=\"https://solutionsforscreenprinters.com/my-account/downloads\" target=\"_blank\" rel=\"noreferrer\">My Account/Downloads</a> select Activation Codes. Do not enter any code beginning with the prefix 2023- or 2024-.</p>" +
                        "<p>Code may have already been successfully migrated. Open the software, if you see a menu named \"Account\" with the ability to Manage Account, you are using the newest version with no need to migrate.</p>"
                    );
                } else if (errorCode === "max_failed_redeem_reached") {
                    setMigrateResponse("<p>Maximum failed attempts reached. Try again in 15 minutes.</p>");
                } else {
                    setMigrateResponse("<p>Failed to migrate.</p>");
                }
            }
        }).catch((error) => {
            setMigrateResponse("<p>Failed to migrate.</p>");
        });
    }

    function serialNumberChanged(e) {
        setSerialNumber(e.target.value);
        setMigrated(false);
    }

    return (
        <ModalDialog title="Migrate iLok License"
            content={
                <div className={classes.main}>
                    <div className={classes.container}>
                        <div className={classes.box}>
                            <label>iLok Activation Code:</label>
                            <input type="text" value={serialNumber} onChange={serialNumberChanged} />
                        </div>
                    </div>
                    {
                        migrateResponse ?
                            <div className={classes.responseText}>
                                <div dangerouslySetInnerHTML={{__html: migrateResponse}}></div>
                            </div> :
                            <div className={classes.helpText}>
                                <p>Enter an active 30 digit iLok activation code. Do not enter any code beginning with the prefix 2023- or 2024-. Remaining time is automatically carried over to FLM.</p>
                                <p>A new FLM activation code using a 2024- prefix consisting of numbers and letters will be added to your FLM account, not your My Account. You will begin to see FLM codes in your My Account upon the renewal of a license. Until that time the new activation code will be displayed in FLM.</p>
                                <p>Download from your Solutions for Screen Printers <a href="https://solutionsforscreenprinters.com/my-account/downloads" target="_blank" rel="noreferrer">My Account</a> the latest Update Installer. Run installer, launch software, login using your FLM account credentials to begin use.</p>
                            </div>
                    }
                </div>
            }
            footer={
                <div className={classes.actions}>
                    <div className={classes.needHelp}>
                        <NeedHelp />
                    </div>
                    <PrimaryButton onClick={props.onClose} negative>
                        Close
                    </PrimaryButton>
                    <PrimaryButton onClick={migrateSerial} disabled={!isSerialValid || isMigrated}>
                        Migrate
                    </PrimaryButton>
                </div>
            }/>
    )
}

export default Migrate;