import './App.css';
import { Route, Switch, Redirect } from 'react-router-dom';
import Header from './modules/Header/Header';
import Loading from './components/Loading/Loading';
import Dashboard from './modules/Dashboard/Dashboard';
import SignIn from './modules/SignIn/SignIn';
import SignUp from './modules/SignUp/SignUp';

function App() {

  function checkLoggedIn() {
    let token = localStorage.getItem("isLoggedIn");
    if (!token) {
      return false;
    }
    return true;
  }

  return (
    <div className='App'>
      <Header />
      <Loading />
      <Switch>
        <Route exact path="/license-manager/sign-up" component={SignUp} />
        <Route exact path="/license-manager" render={routeProps =>
          checkLoggedIn() ? <Redirect to="/license-manager/settings/products" /> : <SignIn {...routeProps} />
        } />
        <Route exact path="/license-manager/forgot-password" render={e => <SignUp isForgotPassword={true} />} />
        <Route path="/license-manager" render={(routeProps) => (
          checkLoggedIn() ? <Dashboard {...routeProps} /> : <Redirect to="/license-manager" />
        )} />
      </Switch>
    </div>
  );
}

export default App;
